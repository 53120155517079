<template>
  <v-card class="register">
    <v-card-text>
      <form>
        <h1>Registrieren</h1>
        <v-text-field
          :error-messages="apiValidationErrors.email"
          v-model="email"
          label="E-Mail"
          required
        ></v-text-field>
        <v-text-field
          :error-messages="apiValidationErrors.password"
          v-model="password"
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          :type="showPassword ? 'text' : 'password'"
          name="input-10-1"
          label="Passwort"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-text-field
          :error-messages="apiValidationErrors.password_confirmation"
          v-model="password_confirmation"
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          :type="showPassword ? 'text' : 'password'"
          name="input-10-1"
          label="Passwort"
          @click:append="showPassword = !showPassword"
        ></v-text-field>

        <v-checkbox v-model="accept_terms">
          <template v-slot:label>
            Ich akzeptiere die &nbsp;
            <a class="ml-1" target="_blank" href="/datenschutz" @click.stop>
              Datenschutzbestimmungen
            </a>
          </template>
        </v-checkbox>
        <v-btn
          class="mr-4"
          @click="register"
          color="success"
          :disabled="true || !accept_terms"
        >
          Anmelden
        </v-btn>
        Aktuell noch keine neuen Anmeldungen möglich.
      </form>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
.register {
  max-width: 500px;
  margin: 0 auto;
}
</style>

<script>
import formMixin from "@/mixins/form-mixin";
export default {
  mixins: [formMixin],
  data() {
    return {
      email: "mail@aaron-enders.de",
      password: "secret123",
      password_confirmation: "secret123",
      accept_terms: false,
      showPassword: false,
      errors: []
    };
  },
  methods: {
    register() {
      this.$store
        .dispatch("register", {
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          accept_terms: this.accept_terms
        })
        .then(() => {
          this.setApiValidation([]);

          this.$swal.fire({
            title: "Bitte bestätigen Sie Ihre E-Mail-Adresse",
            html: `Ihnen wurde soeben eine E-Mail mit dem Link zur Bestätigung geschickt.`,
            icon: "info",
            confirmButtonText: "Okay"
          });
        })
        .catch(e => {
          if (e.response) {
            this.setApiValidation(e.response.data.errors);
          }
        });
    }
  }
};
</script>
